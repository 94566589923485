<template>
  <v-dialog
    :value="openedValue"
    max-width="700px"
    @click:outside="close()"
    @keydown.esc="close()"
  >
    <v-card
      :loading="loading"
    >
      <v-card-title>Modifier la catégorie {{ title }}</v-card-title>
      <v-card-text>
        <v-row
          dense
        >
          <v-col
            cols="12"
            md="12"
            sm="12"
          >
            <v-text-field
              v-model="descriptionValue"
              label="Description"
              outlined
              dense
            />
          </v-col>
        </v-row>
        <v-row
          dense
        >
          <v-col
            cols="12"
            md="6"
            sm="12"
          >
            <div class="subtitle-2 mb-2">
              Nom court :
            </div>
            <v-text-field
              v-model="shortName"
              outlined
              dense
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
            sm="12"
          >
            <div class="subtitle-2 mb-2">
              Nom long :
            </div>
            <v-text-field
              v-model="longName"
              outlined
              dense
            />
          </v-col>
        </v-row>
        <v-row
          dense
        >
          <v-col
            cols="12"
            md="12"
            sm="12"
          >
            <TargetSelector
              v-model="targetsValue"
              multiple
            />
          </v-col>
        </v-row>
        <v-row
          dense
        >
          <v-col
            cols="12"
            md="6"
            sm="12"
          >
            <v-text-field
              v-model="weightValue"
              label="Poids"
              outlined
              dense
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
            sm="12"
          >
            <v-text-field
              v-model="priorityValue"
              label="Priorité"
              type="number"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          outlined
          color="red darken-2"
          @click="close()"
        >
          <v-icon left>
            mdi-cancel
          </v-icon>{{ $t('forms.buttons.cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn
          raised
          color="green darken-1"
          class="white--text"
          @click="save()"
        >
          <v-icon left>
            mdi-check-outline
          </v-icon>{{ $t('forms.buttons.validate') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { countryCodeEmoji } from '@/helpers/countries'
import TargetSelector from '@/components/base/Product/Input/TargetSelector.vue'

export default {
  name: 'CategoryModal',
  components: { TargetSelector },
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    categoryId: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    priority: {
      type: Number,
      default: null,
    },
    weight: {
      type: String,
      default: null,
    },
    targets: {
      type: Array,
      default: () => ([]),
    },
    shortName: {
      type: String,
      default: null,
    },
    longName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      title: this.shortName,
      loading: false,
      message: {
        type: null,
        text: null,
      },
    }
  },
  computed: {
    openedValue: {
      get() { return this.opened },
      set(value) { this.$emit('update:opened', value) },
    },
    descriptionValue: {
      get() { return this.description },
      set(value) { this.$emit('update:description', value) },
    },
    priorityValue: {
      get() { return this.priority },
      set(value) { this.$emit('update:priority', value) },
    },
    weightValue: {
      get() { return this.weight },
      set(value) { this.$emit('update:weight', value) },
    },
    targetsValue: {
      get() { return this.targets },
      set(value) { this.$emit('update:targets', value) },
    },
  },
  methods: {
    countryCodeEmoji,
    close() {
      this.$emit('categoryUpdated', this.message)
      this.loading = false
      this.openedValue = false
    },
    save() {
      this.loading = true
      this.$axios.put('v3/categories/' + this.categoryId, {
        priority: this.priorityValue,
        weight: parseInt(this.weightValue),
        description: this.descriptionValue,
        targets: this.targetsValue,
        short_name: this.shortName,
        long_name: this.longName,
      })
        .then(() => {
          this.message.type = 'green'
          this.message.text = this.$i18n.t('views.category.forms.messages.save_succeed')
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error)
          let error_message = error.response.data['hydra:description'] ?? error.response.data['detail'] ?? 'No context'
          this.message.type = 'error'
          this.message.text = this.$i18n.t('views.category.forms.messages.save_failed', { error: error_message })
        }).finally(() => {
          this.close()
        })
    },
  },
}
</script>

<style scoped>
</style>
